//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "AttendanceData",
  props: {
    attendanceData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeAttendanceTab: "总",
      attendanceTabs: ["今", "总"],
    };
  },
  methods: {
    handleTabClick(tab) {
      this.activeAttendanceTab = tab;
    },
  },
};
